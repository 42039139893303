var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row content-header"},[_c('div',{staticClass:"content-header-left mb-2 col-md-9 col-12"},[_c('div',{staticClass:"row breadcrumbs-top"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0"},[_c('feather-icon',{staticClass:"mr-2",attrs:{"icon":"ArrowLeftIcon","size":"24"},on:{"click":_vm.goBack}}),_vm._v("Chicks ")],1),_vm._m(0)])])])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{attrs:{"method":"post"},on:{"submit":_vm.addSupplyChick}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Purchase Date"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Purchase Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"name":"purchase_date","menu-class":"w-100","calendar-width":"100%"},model:{value:(_vm.FormData.purchase_date),callback:function ($$v) {_vm.$set(_vm.FormData, "purchase_date", $$v)},expression:"FormData.purchase_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Farm Name"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":" Farm Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.farmList,"label":"name","clearable:false":"","reduce":function (e) { return e.id; }},model:{value:(_vm.FormData.farm_id),callback:function ($$v) {_vm.$set(_vm.FormData, "farm_id", $$v)},expression:"FormData.farm_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Hatch No "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":" Hatch No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.hatchList,"label":"hatch_no","clearable:false":""},on:{"input":_vm.getHatchInfo},model:{value:(_vm.FormData.hatch_no),callback:function ($$v) {_vm.$set(_vm.FormData, "hatch_no", $$v)},expression:"FormData.hatch_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Stock Available "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":" Stock Available ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":" Stock Available ","type":"text","name":"stock_available","disabled":""},model:{value:(_vm.hatchInfo.quantity),callback:function ($$v) {_vm.$set(_vm.hatchInfo, "quantity", $$v)},expression:"hatchInfo.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Supply Quantity "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":" Supply Quantity ","rules":{
										required: true,
										max_value: _vm.hatchInfo.quantity,
									}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Supply Quantity","type":"number","name":"quantity"},model:{value:(_vm.FormData.quantity),callback:function ($$v) {_vm.$set(_vm.FormData, "quantity", $$v)},expression:"FormData.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1 mb-3",attrs:{"type":"submit","variant":"primary","value":"Submit"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumb-wrapper"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item active"},[_c('span',{attrs:{"aria-current":"location"}},[_vm._v(" Add ")])])])])}]

export { render, staticRenderFns }