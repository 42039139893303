<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />Chicks
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Add </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<validation-observer ref="simpleRules">
			<b-form method="post" @submit="addSupplyChick">
				<b-card>
					<b-row>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Purchase Date<span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="Purchase Date" rules="required">
									<b-form-datepicker
										name="purchase_date"
										v-model="FormData.purchase_date"
										menu-class="w-100"
										calendar-width="100%"
										class="mb-1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Farm Name<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Farm Name" rules="required">
										<v-select
											v-model="FormData.farm_id"
											:options="farmList"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Hatch No <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Hatch No" rules="required">
										<v-select
											v-model="FormData.hatch_no"
											:options="hatchList"
											label="hatch_no"
											clearable:false
											@input="getHatchInfo"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Stock Available <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Stock Available " rules="required">
										<b-form-input
											v-model="hatchInfo.quantity"
											placeholder=" Stock Available "
											type="text"
											name="stock_available"
											disabled
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Supply Quantity <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name=" Supply Quantity "
										:rules="{
											required: true,
											max_value: hatchInfo.quantity,
										}"
									>
										<b-form-input
											v-model="FormData.quantity"
											placeholder="Supply Quantity"
											type="number"
											name="quantity"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>

						<!-- submit and reset -->
						<b-col md="12">
							<b-button type="submit" variant="primary" value="Submit" class="mr-1 mb-3"> Submit </b-button>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import * as Vue from "vue";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { required, email } from "@validations";
export default {
	components: {
		flatPickr,
		vSelect,
		Logo,
	},

	data() {
		return {
			required,
			selectedHatchList: null,
			FormData: {
				farm_id: null,
				purchase_date: null,
				stock_available: null,
				quantity: null,
			},
			hatchList: [],
			farmList: [],
			hatchDetails: [],
			hatchInfo: [],
			hatch_no: null,
			company_id: null,
		};
	},
	created: function () {
		this.userData = JSON.parse(localStorage.getItem("userData"));
		this.getFarmList(this.userData.company.id);
		this.farmId = this.$route.params.farmId;
		this.getHatchNumberList();
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getFarmList(company_id) {
			axiosIns
				.get("web/getFarmList", { params: { company_id: company_id } })
				.then((response) => {
					this.farmList = response.data.farm_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getHatchNumberList() {
			axiosIns
				.get(`web/hatch-list`)
				.then((response) => {
					this.hatchList = response.data.hatch_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getHatchInfo(hatchDetails) {
			this.hatch_no = hatchDetails.hatch_no;
			let hatch_id = hatchDetails.id;
			axiosIns
				.get(`web/hatch/${this.hatch_no}/info`)
				.then((response) => {
					this.hatchInfo = response.data.hatch_info;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addSupplyChick(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					const hatchery_id = this.$route.params.farmId;
					var data = new FormData();
					data.append("hatch_no", this.hatch_no);
					data.append("farm_id", this.FormData.farm_id);
					data.append("purchase_date", this.FormData.purchase_date);
					data.append("stock_available", this.hatchInfo.quantity);
					data.append("quantity", this.FormData.quantity);
					const farm_id = this.$route.params.farmId;
					axiosIns
						.post(`web/chicks-supply-to-farm`, data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$router.push({
								name: `apps-supply-farm-list`,
							});
						})
						.catch((error) => {
							error.response;
							const data = error.response.data.errors;
							data, "data";
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
